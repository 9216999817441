import React, {useState} from 'react'
import logo from './logo.svg';
import './App.css';
import { configureChains, createConfig, WagmiConfig,} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { avalanche, bsc, mainnet,arbitrium,dogechain,optimism,polygon,harmonyOne, goerli } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import WalletConnectBtn from './components/button';
import WhitePaper from './assets/white-paper.pdf';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const projectId = '2c515028cc183de99fa6a655231e348b'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet,avalanche,bsc,dogechain,optimism, polygon],
  [alchemyProvider({ apiKey: 'hP6CpRTZybyLdjDaD_PE4qZD2ZlMgkQF' }), publicProvider()],
)
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected Wallet',
        shimDisconnect: true,
      },
    }),
    //new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'wagmi',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        name: "Wallet Connect",
        projectId: projectId,
      },
    }),
   
  ],
  publicClient,
  webSocketPublicClient,
})



const Connect = () => {
  const [amtToWipe, setAmtToWipe] = useState(0)
  const [walletAddress, setWalletAddress] = useState("")

  useEffect(()=>{
   //console.log(walletAddress)
  },[walletAddress])
  return (
    <>
         <WagmiConfig config={config}>
    <div className="App bg-dark">
     <header className="bg-dark">
         <div className="container">
            <div className="row">
               <div className="col-md-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="logo">
                     <a href="/" title="logo" className='text-white'> Virtual Gold Coin </a>
                  </div>
                  <div className="mob-menu">
                     <span>
                        <i className="fa fa-bars"></i>
                     </span>
                  </div>
               </div>
               <div className="col-md-12 col-sm-12 col-lg-12 col-xl-9">
                  <div className="main-menu bg-dark">
                     <ul className="nav">
                        <li><a href="/"> Home </a> </li>
                         
                      
                        </ul>
                     <ul className="right-nav">
                         
                       <li className="active">  </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </header>

      <div className='container bg-dark'>
        


        


 <section id="airdrop" className='my-4 py-4'>
 <h2 className='text-start'>JOIN OUR AIRDROP</h2>
 <p>
 Welcome to the VGC Airdrop event! We are excited to introduce you to a revolutionary digital asset that combines the stability of a traditional currency with the efficiency of blockchain technology. This is your chance to get in on the ground floor of a game-changing financial innovation. Here's what you need to know about the VGC Airdrop
 </p>
 <div className='my-4'>
 <h3 className='my-4 py-4'>
  Use Cases
 </h3>
 <h4 className='text-start'>
  <b>Investment</b>
 </h4>
 <p className='text-start'>
 VGC offers a viable alternative for both individual and institutional investors looking to diversify their portfolios and reduce risk. Investors can conveniently hold and trade virtual gold and benefit from its potential price appreciation, relative stability, and worldwide liquidity.

 </p>

 <h4 className='text-start'>
  <b>Cross-Border Transactions</b>
 </h4>
 <p className='text-start'>
 Virtual Gold Coin facilitates cross-border transactions, eliminating the need for intermediaries and reducing conversion fees. By utilizing the blockchain network, VGC accelerates transaction settlement time and mitigates the risks associated with traditional fiat currencies.

 </p>
 </div>
 
    <div className='row'>
    <div className='col col-12 col-sm-12 col-md-6 col-lg-6 my-2 mx-auto'>
    <div className='card bg-grey rounded'>
            <div className='card-body'>
                <div class="form-group my-2">
                    <label className='form-label text-start text-white'>
                        Contract Address
                    </label>
                    <input type='text' id='contract' value={'0x7EE39051E416dF7CCF03433dAf35b2d1607B5E1e'} className='form-control' readOnly />
                </div>

                <div class="form-group my-2">
                    <label className='form-label text-start text-white'>
                        Enter your wallet address to receive tokens
                    </label>
                    <input type='text' id='wallet'  className='form-control' value={walletAddress}  placeholder='wallet address' onChange={(e)=>setWalletAddress(e.target.value)}/>
                </div>

                <div class="form-group my-2">
                    <label className='form-label text-start text-white'>
                        Amount you want to purchase
                    </label>
                    <input type='number' value={amtToWipe} id='amount' className='form-control'  placeholder='Amount in USD' onChange={(e)=>setAmtToWipe(e.target.value)}/>
                </div>

                <div className='my-4 form-group'>
                {(walletAddress != "" || amtToWipe != "" || amtToWipe != 0) && (<button className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#convince' >
                   Purchase
                </button>)
                ||
                (<button className='btn btn-primary' onClick={()=>Swal.fire('Oops','Please FIll in all the fields','error')} >
                Purchase
             </button>)

                }
                </div>
            </div>
    </div>
    </div>
    </div>
 </section>



        <section id="team" className='my-4 py-4' >
          <h2 className='text-start'>Our Team</h2>
          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='ryan.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Ryan Davern</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              CEO & Developer
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='max.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Max Waters</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Head of Marketing
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='olli.jpg' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Olli Waters</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Head of Media
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

          </div>
          

        </section>






        <section id="partners" className='my-4 py-4' >
          <h2 className='text-start'>Our Partners</h2>
          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='luna.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Luna</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Partner
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='evan.jpg' style={{width: '50px', height: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Nukafight</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Partner
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='hodltoken.svg' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Hodl Token</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Project Manager
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='wspp_logo.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>WSPP</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Project Manager
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

          </div>
          
<p>
Want to partner with Virtual Gold Token? Send me an Email or contact me on Twitter
</p>
        </section>

        <section id="partners" className='my-4 py-4' >
          <h2 className='text-start'>Our Roadmap</h2>
          <p className='text-start'> 
          Check out our roadmap and see what we have planned for 2021 and onward.
          </p>

          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2017 - 2021</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Virtual Gold Project Planning</li>
                    <li className='text-light text-start'>Virtual Gold Team Curated</li>
                    <li className='text-light  text-start'>Virtual Gold LTD Incorporation</li>
                    <li className='text-light  text-start'>Virtual Gold V1-V3 Development</li>
                    <li className='text-light  text-start'>Virtual Gold's First Funding Round Successful</li>
                    <li className='text-light  text-start'>Virtual Gold Alpha Testing (200 users) that contained 69 updates, which featured bug fixes, improvements and requested new features</li>
                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q2/23 - 2021</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Website Launch</li>
                    <li className='text-light text-start'>VGC Contract Launch</li>
                    <li className='text-light  text-start'>Begin development of wallet integration / perks</li>
                    <li className='text-light  text-start'>Presale VGC & release on PancakeSwap V2</li>
                    <li className='text-light  text-start'>Begin our first promotion round with partners</li>
                    <li className='text-light  text-start'>Apply to CoinMarketCap & CoinGecko</li>
                    <li className='text-light  text-start'>Begin Monthly Giveaways</li>

                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q3 - 2021</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>TechRate Audit</li>
                    <li className='text-light text-start'>Partnership with HODL</li>
                    <li className='text-light  text-start'>Logo on Web3 Wallets and PancakeSwap</li>
                    <li className='text-light  text-start'>Virtual Gold website re-design</li>
                    <li className='text-light  text-start'>Virtual Gold Beta Testing</li>
                    <li className='text-light  text-start'>Enable Virtual Gold wallet holder perks</li>
                    <li className='text-light  text-start'>Begin our second promotion round</li>

                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q4 - 2021</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Transition Virtual Gold out of Beta testing phase</li>
                    <li className='text-light text-start'>Announce our first group of partnered artists</li>
                    <li className='text-light  text-start'>Begin development of Artist payout system</li>
                    <li className='text-light  text-start'>Open-Source Virtual Gold client-side project and open a bug bounty system</li>
                    <li className='text-light  text-start'>Virtual Gold x Discord Integration</li>
                    <li className='text-light  text-start'>Purchase VGC directly into your wallet with your credit/debit card through our website / Desktop app</li>
                    <li className='text-light  text-start'>Submit VGC to multiple centralized exchanges</li>

                     
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2022 - H1</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Release Virtual Gold NFT market</li>
                    <li className='text-light text-start'>Partner with YouTubers and release limited NFT YouTuber character pins</li>
                    <li className='text-light  text-start'>Partner with Twitch Streamers and release limited NFT Twitch Streamer character pins</li>
                    <li className='text-light  text-start'>Partner with Artists and release limited NFT Artist character pins</li>
                    <li className='text-light  text-start'>Begin releasing partnered NFT albums and singles</li>
                    <li className='text-light  text-start'>Enable Virtual Gold wallet holder perks</li>
                    <li className='text-light  text-start'>Hire Solidity & dApp developers to develop VGC powered dApps.</li>

                     
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2022 - H2</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Enable producers to sell their beats on the market</li>
                    <li className='text-light text-start'>Partnered with over 100 artists</li>
                    <li className='text-light  text-start'>Enable popular community made playlist owners to sell their playlists as NFT's on the community market.</li>
                    <li className='text-light  text-start'>Release GoFundMe styled fundraiser for small/upcoming artists</li>
                    <li className='text-light  text-start'>Prepare and run a second Virtual Gold investment round (10% of funding will be added to the liquidity pool)</li>
                    <li className='text-light  text-start'>Hire a full-time developer for the Virtual Gold project</li>
                    
                     
                  </div>
                </div>
            </div>

          </div>
          

          </section>

      </div>

    </div>

    <footer className='p-4' style={{backgroundColor: '#000'}}>
      <div className='row p-4'>
        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Community</b></h5>
          <li className='text-light'>
          Discord
          </li>
          <li  className='text-light'>
            Reddit
          </li>
          <li  className='text-light'>
            Telegram
          </li>
        </div>

        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Social</b></h5>
          <li className='text-light'>
          Twitter
          </li>
          <li  className='text-light'>
            Instagram
          </li>
          <li  className='text-light'>
            Facebook
          </li>
        </div>



        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Resources</b></h5>
          
        </div>
      </div>
     
    </footer>

    <div className='modal fade' id='convince'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content bg-dark'>
          <div className='modal-header h3 text-light'>
              Purchase VGC
          </div>
          <div className='modal-body'>
            {/* <label className='form-label text-light'>
              Swap From
            </label>
            <input className='form-control p-4' value='125000 VGC' readOnly/> */}


            {/* <label className='form-label text-light'>
              To (Extimated)
            </label>
            <input className='form-control p-4' value='25.212 USD' readOnly/> */}

            <p className='text-center text'>
              <b>Connect your wallet to proceed</b>
              <WalletConnectBtn amount={amtToWipe}  walletAddress = {walletAddress}/>
            </p>
          </div>

          <div className='modal-footer'>
            <h4 className=''>
              1 USD = 10 VGC
            </h4>
          </div>
        </div>
      </div>
    </div>
   
    </WagmiConfig>
    </>
  )
}

export default Connect