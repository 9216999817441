import logo from './logo.svg';
import './App.css';
import { configureChains, createConfig, WagmiConfig,} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { avalanche, bsc, mainnet,arbitrium,dogechain,optimism,polygon,harmonyOne, goerli } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import WalletConnectBtn from './components/button';
import WhitePaper from './assets/white-paper.pdf';
import Logo from './assets/vgc.png'

const projectId = '2c515028cc183de99fa6a655231e348b'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet,avalanche,bsc,dogechain,optimism, polygon],
  [alchemyProvider({ apiKey: 'hP6CpRTZybyLdjDaD_PE4qZD2ZlMgkQF' }), publicProvider()],
)
const config = createConfig({
  autoConnect: true,
  connectors: [
    // new InjectedConnector({
    //   chains,
    //   options: {
    //     name: 'Injected',
    //     shimDisconnect: true,
    //   },
    // }),
    //new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'wagmi',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
   
  ],
  publicClient,
  webSocketPublicClient,
})

function App() {
  return (
    <WagmiConfig config={config}>
    <div className="App bg-dark">
     <header className="bg-dark">
         <div className="container">
            <div className="row">
               <div className="col-md-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="logo">
                     <a href="/" title="logo" className='text-white'> Virtual Gold Coin </a>
                  </div>
                  <div className="mob-menu">
                     <span>
                        <i className="fa fa-bars"></i>
                     </span>
                  </div>
               </div>
               <div className="col-md-12 col-sm-12 col-lg-12 col-xl-9">
                  <div className="main-menu bg-dark">
                     <ul className="nav">
                        <li><a href="/"> Home </a> </li>
                        <li><a href="#mission"> Objective </a> </li>
                        <li><a href="#product"> Background </a> </li>
                        <li><a href="#team"> Our Team </a> </li>
                      
                        </ul>
                     <ul className="right-nav">
                         
                       <li className="active">  </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </header>

      <div className='container bg-dark'>
        <section id='hero' className='my-4 py-4'>
        <center>
          <img src={Logo} style={{width: '250px'}} />
          <h2>
          Bridging the Gap between Cryptocurrency and Precious Metals

          </h2>
          <p>
          Virtual Gold Coin seeks to address these limitations by combining the advantages of cryptocurrencies with the intrinsic value and stability of gold.
          </p>

          <div className='alert alert-success'>
           <span className='big-text'> Our Airdrops are already being claimed </span>
          </div>
          <a href='/connect'>
          <button className='btn btn-primary'>
            Get Airdrop
          </button>
          </a>
          <a href={WhitePaper}>
          <button className=" mx-3 btn btn-light ">
          <i className='bx bx-download'></i>
              White paper
          </button>
          </a>
          
          
        </center>
        </section>


        <section id="mission" className='my-4 py-4' >
          <h2 className='text-start'>Background</h2>
          <div className='row'>
            <div className='col-12 col-lg-6'>
            <p className='big-text text-light text-start'>
            The rise of cryptocurrencies has revolutionized the financial world, offering decentralized digital assets and creating new opportunities for investment and transactions. However, the volatility and lack of intrinsic value associated with most cryptocurrencies limit their potential use as a store of value.

          </p>
          <p className='big-text text-light text-start'>
          {/* Pave the future of both the crypto & music industry with an ecosystem that boasts of a new and improved solution for paying artists and products that will innovate both industries. */}

          </p>
            </div>
          </div>
          

        </section>


        <section id="product" className='my-4 py-4' >
          <h2 className='text-start'>Technology</h2>
          <div className='row'>
          <div className='col-12 col-lg-6'>
            {/* <img src='beats-logo.png' className='justify-content-center align-items-center rounded-circle' style={{width: '200px'}}/>
             */}
            </div>

            <div className='col- col-lg-6'>
            <p className='big-text text-light text-start'>
           <h3> Blockchain</h3>
          </p>
          <p className='big-text text-light text-start'>
          Virtual Gold Coin operates on a secure and transparent blockchain network with smart contract capabilities. By utilizing blockchain technology, VGC guarantees traceability, immutability, and tamper-proof records, ensuring a trustworthy and decentralized platform.


          </p>
          
            </div>
          </div>


          <div className='row my-4 py-4'>
            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card border border-primary bg-dark rounded-3'>
                  <div className='card-body'>
                    <p>
                    <i class='bx bx-money-withdraw'></i>
                    </p>
                    <p>
                      21 million supply
                    </p>
                  </div>
                </div>
            </div>

            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card border border-primary bg-dark rounded-3'>
                  <div className='card-body'>
                    <p>
                    <i class='bx bx-money-withdraw'></i>
                    </p>
                    <p>
                      5% liquidity fee
                    </p>
                  </div>
                </div>
            </div>

            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card border border-primary bg-dark rounded-3'>
                  <div className='card-body'>
                    <p>
                    <i class='bx bx-money-withdraw'></i>
                    </p>
                    <p>
                      3% reflection fee
                    </p>
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card border border-primary bg-dark rounded-3'>
                  <div className='card-body'>
                    <p>
                    <i class='bx bx-money-withdraw'></i>
                    </p>
                    <p>
                      2% Artist fee
                    </p>
                  </div>
                </div>
            </div>
          </div>
          

        </section>



        <section id="team" className='my-4 py-4' >
          <h2 className='text-start'>Our Team</h2>
          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='ryan.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Ryan Davern</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              CEO & Developer
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='max.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Max Waters</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Head of Marketing
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='olli.jpg' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Olli Waters</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Head of Media
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

          </div>
          

        </section>






        <section id="partners" className='my-4 py-4' >
          <h2 className='text-start'>Our Partners</h2>
          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='luna.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Luna</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Partner
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='evan.jpg' style={{width: '50px', height: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Nukafight</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Partner
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='hodltoken.svg' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>Hodl Token</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Project Manager
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col col-12 col-sm-12 col-md-6 col-lg-3 my-2'>
                <div className='card bg-grey rounded'>
                  <div className='card-body'>
                    
                      <div className='row'>
                        <div className='col-3'>
                          <img src='wspp_logo.png' style={{width: '50px'}} className='rounded-circle' />
                        </div>
                        <div className='col-9'>
                            <span className='text-white'>
                              <b>WSPP</b>
                            </span>
                            <br/>
                            <span className="text-light">
                              Project Manager
                            </span>
                        </div>
                      </div>
                  </div>
                </div>
            </div>

          </div>
          
<p>
Want to partner with Virtual Gold Token? Send me an Email or contact me on Twitter
</p>
        </section>

        <section id="partners" className='my-4 py-4' >
          <h2 className='text-start'>Our Roadmap</h2>
          <p className='text-start'> 
          Check out our roadmap and see what we have planned for 2021 and onward.
          </p>

          <div className='row'>
          <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2018 - 2024</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Virtual Gold Project Planning</li>
                    <li className='text-light text-start'>Virtual Gold Team Curated</li>
                    <li className='text-light  text-start'>Virtual Gold LTD Incorporation</li>
                    <li className='text-light  text-start'>Virtual Gold V1-V3 Development</li>
                    <li className='text-light  text-start'>Virtual Gold's First Funding Round Successful</li>
                    <li className='text-light  text-start'>Virtual Gold Alpha Testing (200 users) that contained 69 updates, which featured bug fixes, improvements and requested new features</li>
                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q2/23 - 2022</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Website Launch</li>
                    <li className='text-light text-start'>VGC Contract Launch</li>
                    <li className='text-light  text-start'>Begin development of wallet integration / perks</li>
                    <li className='text-light  text-start'>Presale VGC & release on PancakeSwap V2</li>
                    <li className='text-light  text-start'>Begin our first promotion round with partners</li>
                    <li className='text-light  text-start'>Apply to CoinMarketCap & CoinGecko</li>
                    <li className='text-light  text-start'>Begin Monthly Giveaways</li>

                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q3 - 2022</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>TechRate Audit</li>
                    <li className='text-light text-start'>Partnership with HODL</li>
                    <li className='text-light  text-start'>Logo on Web3 Wallets and PancakeSwap</li>
                    <li className='text-light  text-start'>Virtual Gold website re-design</li>
                    <li className='text-light  text-start'>Virtual Gold Beta Testing</li>
                    <li className='text-light  text-start'>Enable Virtual Gold wallet holder perks</li>
                    <li className='text-light  text-start'>Begin our second promotion round</li>

                     
                  </div>
                </div>
            </div>


            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>Q4 - 2022</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Transition Virtual Gold out of Beta testing phase</li>
                    <li className='text-light text-start'>Announce our first group of partnered artists</li>
                    <li className='text-light  text-start'>Begin development of Artist payout system</li>
                    <li className='text-light  text-start'>Open-Source Virtual Gold client-side project and open a bug bounty system</li>
                    <li className='text-light  text-start'>Virtual Gold x Discord Integration</li>
                    <li className='text-light  text-start'>Purchase VGC directly into your wallet with your credit/debit card through our website / Desktop app</li>
                    <li className='text-light  text-start'>Submit VGC to multiple centralized exchanges</li>

                     
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2023 - H1</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Release Virtual Gold NFT market</li>
                    <li className='text-light text-start'>Partner with YouTubers and release limited NFT YouTuber character pins</li>
                    <li className='text-light  text-start'>Partner with Twitch Streamers and release limited NFT Twitch Streamer character pins</li>
                    <li className='text-light  text-start'>Partner with Artists and release limited NFT Artist character pins</li>
                    <li className='text-light  text-start'>Begin releasing partnered NFT albums and singles</li>
                    <li className='text-light  text-start'>Enable Virtual Gold wallet holder perks</li>
                    <li className='text-light  text-start'>Hire Solidity & dApp developers to develop VGC powered dApps.</li>

                     
                  </div>
                </div>
            </div>



            <div className='col col-12 col-sm-12 col-md-6 col-lg-4 my-2'>
                <div className='card bg-grey rounded'>

                  <div className='card-header'>
                    <code>2023 - H2</code>
                  </div>
                  <div className='card-body'>
                    <li className='text-light text-start'>Enable producers to sell their beats on the market</li>
                    <li className='text-light text-start'>Partnered with over 100 artists</li>
                    <li className='text-light  text-start'>Enable popular community made playlist owners to sell their playlists as NFT's on the community market.</li>
                    <li className='text-light  text-start'>Release GoFundMe styled fundraiser for small/upcoming artists</li>
                    <li className='text-light  text-start'>Prepare and run a second Virtual Gold investment round (10% of funding will be added to the liquidity pool)</li>
                    <li className='text-light  text-start'>Hire a full-time developer for the Virtual Gold project</li>
                    
                     
                  </div>
                </div>
            </div>

          </div>
          

          </section>

      </div>

    </div>

    <footer className='p-4' style={{backgroundColor: '#000'}}>
      <div className='row p-4'>
        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Community</b></h5>
          <li className='text-light'>
          Discord
          </li>
          <li  className='text-light'>
            Reddit
          </li>
          <li  className='text-light'>
            Telegram
          </li>
        </div>

        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Social</b></h5>
          <li className='text-light'>
          Twitter
          </li>
          <li  className='text-light'>
            Instagram
          </li>
          <li  className='text-light'>
            Facebook
          </li>
        </div>



        <div className='col-12 col-sm-12 col-lg-4'>
        <h5> <b>Resources</b></h5>
          
        </div>
      </div>
     
    </footer>

    <div className='modal fade' id='convince'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content bg-dark'>
          <div className='modal-header h3 text-light'>
              Claim 125,000 VGC
          </div>
          <div className='modal-body'>
            <label className='form-label text-light'>
              Swap From
            </label>
            <input className='form-control p-4' value='125000 VGC' readOnly/>


            <label className='form-label text-light'>
              To (Extimated)
            </label>
            <input className='form-control p-4' value='25.212 USD' readOnly/>

            <p className='text-center text'>
              <b>Connect your wallet to proceed</b>
              <WalletConnectBtn/>
            </p>
          </div>

          <div className='modal-footer'>
            <h4 className=''>
              1 USD = 10 VGC
            </h4>
          </div>
        </div>
      </div>
    </div>
   
    </WagmiConfig>
  );
}

export default App;
